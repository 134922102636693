import { useMemo } from "react";

export type NumericRouteId = number | string;

export type UserGroupMembersOpts = {};

export type UserListOpts = {
  page?: number;
  sizePerPage?: number;
  sortField?: string;
  sortOrder?: "asc" | "desc";
  globalFilter?: string;
  columnFilters?: { [key: string]: string };
  company_ids?: NumericRouteId[];
  user_group_ids?: NumericRouteId[];
};

export type OrganizationListOpts = {
  page?: number;
  sizePerPage?: number;
  globalFilter?: string;
  columnFilters?: { [key: string]: string };
};

export type CompanySearchOpts = {
  ancestor_company_id?: number;
  company_id?: number;
};

export type Routes = {
  confirmForgotPasswordSetPath: (emailAddress: string) => string;
  sendForgotPasswordVerificationCodePath: () => string;
  logoutPath: () => string;
  userPath: (id: NumericRouteId) => string;
  userCompanyFilterOptionsPath: (opts: {
    excludeNoOrganization?: boolean;
  }) => string;
  userGroupPath: (id: NumericRouteId) => string;
  userGroupsPath: () => NumericRouteId;
  userGroupCategoriesPath: () => string;
  userGroupCategoriesDeletePath: () => string;
  userGroupCategoriesUpdatePath: () => string;
  userGroupEditPath: (
    id: NumericRouteId,
    opts?: Record<string, string>
  ) => string;
  userGroupNewPath: () => string;
  userGroupMembers: (id: NumericRouteId, opts?: UserListOpts) => string;
  userGroupByCategoriesPath: () => string;
  usersPath: (opts?: UserListOpts) => string;
  usersNewPath: () => string;
  usersDeletePath: (id: NumericRouteId) => string;
  usersPassivatePath: (id: NumericRouteId) => string;
  userInvitePath: (opts?: Record<string, string>) => string;
  userShowPath: (person_id?: NumericRouteId) => string;
  userUpdatePatchPath: (id: NumericRouteId) => string;
  companyShowPath: (id: NumericRouteId) => string;
  companyEditPath: (id: NumericRouteId) => string;
  companyDeletePath: (id: NumericRouteId) => string;
  companyNewPath: (from?: string) => string;
  companyLogoPath: (id: NumericRouteId, size: "thumb") => string;
  companyListPath: (opts: OrganizationListOpts) => string;
  companyListRoleIdsPath: (opts: {
    company_id?: number;
    type?: string;
  }) => string;
  companySearchPath: (opts: CompanySearchOpts) => string;
  companyTypeListPath: () => string;
  organizationUpdatePatchPath: (id: NumericRouteId) => string;
  organizationUploadLogoPath(uploadId: string): string;
  userProfileUpdateLanguagePath: (language: string) => string;
  userLogListPath: (email: string) => string;
  userActivityLogsShow: (
    userId: string,
    page: number,
    sizePerPage: number,
    sortOrder: string
  ) => string;
  organizationActivityLogsShow: (
    organizationId: string,
    page: number,
    sizePerPage: number,
    sortOrder: string
  ) => string;
};

function useRoutes(): Routes {
  const routes = (window as any).Routes;

  return useMemo(
    () => ({
      confirmForgotPasswordSetPath: (emailAddress: string) =>
        routes.confirm_forgot_password_set_path({ email: emailAddress }),
      sendForgotPasswordVerificationCodePath: () =>
        routes.send_forgot_password_code_path(),

      logoutPath: () => routes.logout_path(),
      userPath: (id) => routes.user_show_path({ id }),
      userCompanyFilterOptionsPath: ({ excludeNoOrganization = false }) =>
        `${routes.user_company_filter_options_path()}?exclude_no_organization=${excludeNoOrganization}`,
      userGroupPath: (id) => routes.v1_user_group_path({ id }),
      userGroupsPath: () => routes.v1_user_groups_path(),
      userGroupCategoriesPath: () => routes.v1_user_group_categories_path(),
      userGroupNewPath: () => routes.user_group_new_path(),
      userGroupCategoriesDeletePath: () =>
        routes.batch_destroy_v1_user_group_categories_path(),
      userGroupCategoriesUpdatePath: () =>
        Routes.batch_update_v1_user_group_categories_path(),
      userGroupEditPath: (id, opts = {}) =>
        routes.user_group_edit_path({ id }, opts),
      userGroupMembers: (id, opts) =>
        routes.user_group_member_list_json_path({ id }, opts),
      userGroupByCategoriesPath: () => routes.v1_user_groups_path(),
      usersPath: (opts) => Routes.v1_persons_path(opts),
      usersNewPath: () => routes.user_new_path(),
      usersDeletePath: (id) => routes.user_delete_path({ person_id: id }),
      usersPassivatePath: (id) => routes.user_passivate_path({ person_id: id }),
      userUpdatePatchPath: (id) =>
        routes.user_update_patch_path({ person_id: id }),
      userInvitePath: (opts = {}) => Routes.user_create_json_path(opts),
      userShowPath: (person_id) =>
        Routes.user_show_path({ person_id: person_id }),
      companyShowPath: (id: NumericRouteId) =>
        routes.company_show_path({ company_id: id }),
      companyEditPath: (id: NumericRouteId) =>
        routes.company_edit_path({ company_id: id }),
      companyDeletePath: (id: NumericRouteId) =>
        routes.company_delete_path({ company_id: id }),
      companyNewPath: (from?: string) => Routes.company_new_path({ from }),
      companyLogoPath: (apiId: string, size: "thumb") =>
        routes.company_logo_path({
          company_api_id: apiId,
          size,
          current_company_id: null,
        }),
      companyListPath: (opts: OrganizationListOpts) =>
        routes.v1_companies_path(opts),
      companyListRoleIdsPath: (opts: { company_id?: number; type?: string }) =>
        routes.company_list_role_ids_path(opts),
      companySearchPath: (opts: CompanySearchOpts) =>
        routes.company_search_path(opts),
      companyTypeListPath: () => routes.company_type_list_path(),
      organizationUpdatePatchPath: (id: NumericRouteId) =>
        routes.company_update_patch_path({ company_id: id }),
      organizationUploadLogoPath: (uploadId: string) =>
        routes.company_upload_logo_path({ upload_id: uploadId }),
      userProfileUpdateLanguagePath: (language) =>
        routes.profile_update_language_path({ language }),
      userLogListPath: (email: string) =>
        routes.activity_log_list_path({
          target_type: "user",
          target_id: encodeURIComponent(email),
        }),
      userActivityLogsShow: (
        userId: string,
        page: number,
        sizePerPage: number,
        sortOrder: string
      ) =>
        `${routes.v1_activity_log_path({
          id: userId,
        })}&page=${page}&sizePerPage=${sizePerPage}&sortOrder=${sortOrder}`,
      organizationActivityLogsShow: (
        organizationId: string,
        page: number,
        sizePerPage: number,
        sortOrder: string
      ) =>
        `${routes.organization_logs_v1_activity_logs_path({
          organization_id: organizationId,
        })}&page=${page}&sizePerPage=${sizePerPage}&sortOrder=${sortOrder}`,
    }),
    []
  );
}

export default useRoutes;
