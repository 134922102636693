import useCSRFToken from "@hooks/useCSRFToken";
import useFetch from "@hooks/useFetch";
import useRoutes from "@hooks/useRoutes";
import { useCallback, useMemo } from "react";

export default function useForgotPassword() {
  const { doFetch: doRequestCode, isFetching: isRequestingVerificationCode } =
    useFetch();
  const csrfToken = useCSRFToken();
  const routes = useRoutes();

  const requestVerificationCode = useCallback(
    async (email: string) => {
      try {
        const res = await doRequestCode(
          routes.sendForgotPasswordVerificationCodePath(),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken,
            },
            body: JSON.stringify({ email_address: email }),
          }
        );
        return res.ok;
      } catch (e) {
        console.error("Error sending verification code", e);
        return false;
      }
    },
    [csrfToken, doRequestCode, routes]
  );

  return useMemo(
    () => ({
      requestVerificationCode,
      isRequestingVerificationCode,
    }),
    [requestVerificationCode, isRequestingVerificationCode]
  );
}
