import { useMemo } from "react";

export type NavigateFunction = (path: string) => void;
export type PushStateFunction = (path: string) => void;

type Turbolinks = {
  visit: (path: string) => void;
  controller: {
    pushHistoryWithLocationAndRestorationIdentifier: (
      url: string,
      restorationId: string
    ) => void;
  };
  uuid(): string;
};

function useTurbolinks(): {
  navigate: NavigateFunction;
  pushState: PushStateFunction;
} {
  const turbolinks = (window as any).Turbolinks as Turbolinks;
  return useMemo(
    () => ({
      pushState: (path: string) => {
        turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(
          path,
          turbolinks.uuid()
        );
      },
      navigate: (path: string) => {
        turbolinks.visit(path);
      },
    }),
    []
  );
}

export default useTurbolinks;
