import React, { useEffect, useState } from "react";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import TextField from "@oriola-origo/core/lib/TextField";
import IconButton from "@oriola-origo/core/lib/IconButton";
import InputAdornment from "@oriola-origo/core/lib/InputAdornment";
import Tooltip from "@oriola-origo/core/lib/Tooltip";

interface IShowPasswordProps {
  name: string;
  placeHolder: string;
  isErrorKey: boolean;
  onValueChange: (value: string) => void;
  helperText?: string;
  onFocusChange: () => void;
  onBlurChange: () => void;
  isRequired: boolean;
  disabled?: boolean;
}

export function getBrowserName() {
  const userAgent = navigator.userAgent;

  if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
    return "Chrome";
  } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
    return "Safari";
  } else if (userAgent.includes("Firefox")) {
    return "Firefox";
  } else if (userAgent.includes("Edge") || userAgent.includes("Edg")) {
    return "Edge";
  } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
    return "Internet Explorer";
  } else {
    return "Unknown";
  }
}

export function handleClickShowPassword(showPassword, setShowPassword) {
  setShowPassword(!showPassword);
}

export function handleCapsLock(e, setIsCapsLockEnabled) {
  if (e.getModifierState && e.getModifierState("CapsLock")) {
    setIsCapsLockEnabled(true);
  } else {
    setIsCapsLockEnabled(false);
  }
}

const ShowPassword = (props: IShowPasswordProps) => {
  const {
    name,
    placeHolder,
    isErrorKey,
    onValueChange,
    helperText,
    onFocusChange,
    onBlurChange,
    isRequired,
    disabled,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [isCapsLockEnabled, setIsCapsLockEnabled] = useState(false);

  useEffect(() => {
    window.addEventListener("keydown", (e) =>
      handleCapsLock(e, setIsCapsLockEnabled)
    );
    window.addEventListener("keyup", (e) =>
      handleCapsLock(e, setIsCapsLockEnabled)
    );

    return () => {
      window.removeEventListener("keydown", (e) =>
        handleCapsLock(e, setIsCapsLockEnabled)
      );
      window.removeEventListener("keyup", (e) =>
        handleCapsLock(e, setIsCapsLockEnabled)
      );
    };
  }, []);

  return (
    <TextField
      id="password"
      sx={{ paddingBottom: "5px !important" }}
      required={isRequired}
      fullWidth
      name={name}
      disabled={disabled}
      label={placeHolder}
      helperText={isErrorKey && I18n.t(helperText)}
      error={isErrorKey}
      onChange={(e) => onValueChange(e.target.value)}
      onFocus={onFocusChange}
      onBlur={onBlurChange}
      InputProps={{
        type: showPassword ? "text" : "password",
        endAdornment: !disabled ? (
          <InputAdornment position="end">
            <IconButton
              onClick={() =>
                handleClickShowPassword(showPassword, setShowPassword)
              }
              edge="end"
            >
              <FontIcon>
                {showPassword ? "visibility_off" : "visibility"}
              </FontIcon>
            </IconButton>
            {getBrowserName() !== "Safari" && isCapsLockEnabled && (
              <Tooltip arrow placement="top" title={I18n.t("caps_lock_on")}>
                <FontIcon sx={{ ml: 1, pt: "3px" }}>keyboard_capslock</FontIcon>
              </Tooltip>
            )}
          </InputAdornment>
        ) : undefined,
      }}
    />
  );
};

export default ShowPassword;
