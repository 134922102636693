import { useMemo } from "react";

export type TranslateFunction = (key: string, params?: any) => string;

export type UseTranslationsOutput = {
  language: string;
  t: TranslateFunction;
};

function useTranslations(): UseTranslationsOutput {
  return useMemo(
    () => ({
      t: I18n.t,
      language: I18n.locale,
    }),
    []
  );
}

export default useTranslations;
