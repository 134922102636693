import { useMemo } from "react";

function useCSRFToken() {
  return useMemo(
    () =>
      document
        .querySelector("meta[name='csrf-token']")
        ?.getAttribute("content") ?? null,
    []
  );
}

export default useCSRFToken;
