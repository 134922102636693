import { useCallback, useMemo, useState } from "react";

function useFetch() {
  const [isFetching, setIsFetching] = useState(false);
  const doFetch = useCallback(
    async (url: string, options: RequestInit = {}) => {
      setIsFetching(true);
      try {
        return await fetch(url, options);
      } finally {
        setIsFetching(false);
      }
    },
    []
  );

  return useMemo(() => ({ doFetch, isFetching }), [doFetch, isFetching]);
}

export default useFetch;
