import { useState, useEffect, useCallback, useMemo } from "react";
import useTurbolinks from "./useTurbolinks";

export type UseSearchParamsOutput = [
  URLSearchParams,
  (params: URLSearchParams | Record<string, string>) => void,
];

function useSearchParams(): UseSearchParamsOutput {
  const turbolinks = useTurbolinks();
  const [searchParamsState, setSearchParamsState] = useState(() => {
    return new URLSearchParams(window.location.search);
  });

  useEffect(() => {
    const handlePopState = () => {
      setSearchParamsState(new URLSearchParams(window.location.search));
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const setSearchParams = useCallback(
    (params: URLSearchParams | Record<string, string>) => {
      const newSearchParams = new URLSearchParams(params);
      const newSearchString = newSearchParams.toString();

      const newUrl =
        window.location.pathname +
        (newSearchString ? `?${newSearchString}` : "");

      turbolinks.pushState(newUrl);
      setSearchParamsState(newSearchParams);
    },
    [searchParamsState, turbolinks]
  );

  return useMemo(
    () => [searchParamsState, setSearchParams],
    [searchParamsState, setSearchParams]
  );
}

export default useSearchParams;
