import React, { useEffect, useState } from "react";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import Typography from "@oriola-origo/core/lib/Typography";
import TextField from "@oriola-origo/core/lib/TextField";
import Button from "@oriola-origo/core/lib/Button";
import { useTheme } from "@oriola-origo/core/lib/styles";
import useMediaQuery from "@oriola-origo/core/lib/useMediaQuery";
import Box from "@oriola-origo/core/lib/Box";
import Alert from "@oriola-origo/core/lib/Alert";
import useRoutes from "@hooks/useRoutes";
import { onPasswordChange, onPasswordConfirmationChange } from "./form_utils";
import PasswordHints from "./components/password_hints";
import { Form } from "../common/form";
import ShowPassword from "../common/show_password";
import useSearchParams from "@hooks/useSearchParams";
import useForgotPassword from "@services/user/hooks/useForgotPassword";
import useSnackbar from "@hooks/useSnackbar";
import useTranslations from "@hooks/useTranslations";
import SnackbarProvider from "@oriola-origo/core/lib/Snackbar/SnackbarProvider";

export interface IForgotPasswordSetProps {
  email: string;
  email_verified: boolean;
  password_change_required: boolean;
  password_expired: boolean;
  password_chars: string;
}

const SECOND_IN_MS = 1000;
const REQUEST_VERIFICATION_CODE_TIMEOUT_SECONDS = 30;

const ForgotPasswordSetInternal = ({
  email,
  email_verified,
  password_change_required,
  password_chars,
  password_expired,
}: Readonly<IForgotPasswordSetProps>) => {
  const [searchParams] = useSearchParams();
  const verificationCodeSentInitially =
    searchParams.get("verification_code_sent") === "true";
  const { confirmForgotPasswordSetPath, logoutPath } = useRoutes();
  const [formErrors, setFormErrors] = useState({});
  const [typedUserPassword, setTypedUserPassword] = useState("");
  const [showPasswordHints, setShowPasswordHints] = useState(false);
  const [verificationCode, setVerificationCode] = useState(null);
  const [isInitialVerificationCodeSent, setIsInitialVerificationCodeSent] =
    useState(verificationCodeSentInitially);
  const [requestAllowedTimer, setRequestAllowedTimer] = useState(
    verificationCodeSentInitially
      ? REQUEST_VERIFICATION_CODE_TIMEOUT_SECONDS
      : 0
  );
  const theme = useTheme();
  const { t } = useTranslations();
  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down("sm"));
  const { setSnackMessage } = useSnackbar();
  const { isRequestingVerificationCode, requestVerificationCode } =
    useForgotPassword();

  useEffect(() => {
    const handle = setInterval(() => {
      setRequestAllowedTimer((prev) => (prev > 0 ? prev - 1 : prev));
    }, SECOND_IN_MS);
    return () => {
      clearInterval(handle);
    };
  }, [setRequestAllowedTimer]);

  const handleSendVerificationCodeClick = async () => {
    setRequestAllowedTimer(REQUEST_VERIFICATION_CODE_TIMEOUT_SECONDS);
    await requestVerificationCode(email);
    setIsInitialVerificationCodeSent(true);
    setSnackMessage(t("verification_code_sent"), "success");
  };

  const resolveSendVerificationCodeButtonText = () => {
    if (!isInitialVerificationCodeSent) {
      return t("send_verification_code");
    }

    const parts = [t("resend_verification_code")];
    if (requestAllowedTimer > 0) {
      parts.push(`(${requestAllowedTimer})`);
    }

    return parts.join(" ");
  };

  const renderContent = () => {
    const hasErrors = Object.keys(formErrors).some((key) => !!formErrors[key]);
    return (
      <Box>
        <Typography
          variant="h5"
          fontWeight={600}
          sx={{ marginBottom: (theme) => theme.spacing(3) }}
        >
          {t("verification_code")}
        </Typography>
        <Box mb={3}>
          <Button
            fullWidth={true}
            variant={isInitialVerificationCodeSent ? "outlined" : "contained"}
            color="secondary"
            isLoading={isRequestingVerificationCode}
            disabled={requestAllowedTimer > 0}
            onClick={handleSendVerificationCodeClick}
          >
            {resolveSendVerificationCodeButtonText()}
          </Button>
        </Box>

        {isInitialVerificationCodeSent && (
          <>
            <Box mb={3}>
              <TextField
                fullWidth={true}
                required={true}
                disabled={!isInitialVerificationCodeSent}
                label={t("verification_code")}
                name="code"
                autoComplete="cc-name"
                autoFocus={true}
                onChange={(e) => setVerificationCode(e.target.value)}
                error={!verificationCode && verificationCode !== null}
              />
            </Box>
            <Box
              display="grid"
              gridTemplateColumns={isSmOrSmaller ? "1fr" : "auto auto"}
              columnGap={2}
              mb={3}
            >
              <Box>
                <ShowPassword
                  name="password"
                  placeHolder={t("password")}
                  isErrorKey={!!formErrors["user_password"]}
                  onValueChange={(value) => {
                    onPasswordChange(value, setFormErrors, () => {});
                    setTypedUserPassword(value);
                  }}
                  disabled={!isInitialVerificationCodeSent}
                  onFocusChange={() => setShowPasswordHints(true)}
                  onBlurChange={() => setShowPasswordHints(false)}
                  isRequired={true}
                />
                {showPasswordHints && (
                  <PasswordHints
                    password_chars={password_chars}
                    typedUserPassword={typedUserPassword}
                  />
                )}
              </Box>
              <Box>
                <ShowPassword
                  name="password_confirmation"
                  placeHolder={t("retype_password")}
                  isErrorKey={!!formErrors["password_confirmation"]}
                  helperText={"error_invalid_password_confirmation"}
                  onValueChange={(value) =>
                    onPasswordConfirmationChange(
                      value,
                      setFormErrors,
                      typedUserPassword
                    )
                  }
                  disabled={!isInitialVerificationCodeSent}
                  onFocusChange={() => setShowPasswordHints(false)}
                  onBlurChange={() => setShowPasswordHints(false)}
                  isRequired={true}
                />
              </Box>
            </Box>

            <Button
              color="secondary"
              variant="contained"
              type="submit"
              fullWidth={true}
              disabled={
                hasErrors || typedUserPassword.length === 0 || !verificationCode
              }
              sx={{ padding: (theme) => theme.spacing(1, 0) }}
            >
              {t("log_in")}
            </Button>
          </>
        )}
      </Box>
    );
  };

  return (
    <Form action={confirmForgotPasswordSetPath(email)}>
      {(password_expired || password_change_required) && (
        <Alert severity="info" variant="filled" sx={{ mb: 2 }}>
          {t("password_change_required_message")}
        </Alert>
      )}

      <Alert severity="info" variant="filled" sx={{ mb: 2 }}>
        {isInitialVerificationCodeSent ? (
          <>
            {t("set_forgot_password_message")}
            {email_verified || (
              <Box mt={2}>
                {t("forgot_password_ensure_correct_email_message")}
              </Box>
            )}
          </>
        ) : (
          t("set_forgot_password_help")
        )}
      </Alert>

      {renderContent()}
      <hr />
      <div className="d-flex justify-content-center mb-n2">
        <a style={{ fontSize: "14px" }} href={logoutPath()}>
          {t("cancel")}
        </a>
      </div>
    </Form>
  );
};

const ForgotPasswordSet = (props: IForgotPasswordSetProps) => {
  return (
    <OriolaThemeProvider>
      <SnackbarProvider>
        <ForgotPasswordSetInternal {...props} />
      </SnackbarProvider>
    </OriolaThemeProvider>
  );
};

export default ForgotPasswordSet;
